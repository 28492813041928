import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazySocialShare = lazy(
  () => import("./social-share-cnKIieDC.js").then((module) => ({
    default: module.SocialShare
  }))
);
function SocialShare({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazySocialShare, { ...props });
}
const useSocialShareContent = () => {
  return useMemo(
    () => ({
      socialShare: {
        component: SocialShare
      }
    }),
    []
  );
};
export {
  LazySocialShare as SocialShare,
  useSocialShareContent
};
